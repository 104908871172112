import { template as template_a2d3b143f59b4649a4a5852cc5fcd8c0 } from "@ember/template-compiler";
const FKLabel = template_a2d3b143f59b4649a4a5852cc5fcd8c0(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
