import { template as template_86190e4cd1f249d985a41e739533d2d0 } from "@ember/template-compiler";
const SidebarSectionMessage = template_86190e4cd1f249d985a41e739533d2d0(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
